import useClient from "hooks/atPort/useClient";

const useUpdateUser = () => {
  const { client } = useClient();

  const update = async (id: string | undefined, data: Omit<SalesforceUser, "id">) => {
    if (!id) throw new Error("id is required");

    const { status } = await client({
      method: "PUT",
      url: `/lessee/user/${id}`,
      data,
    });
    return status;
  };

  return { update };
};

export default useUpdateUser;

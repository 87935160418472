import {
  faParking,
  faSignOutAlt,
  faUserFriends,
  faHomeAlt,
  faCar,
  faBuilding,
  faHardDrive,
  faEnvelope,
  faTachographDigital,
  faMicrochip,
} from "@fortawesome/free-solid-svg-icons";
import { getAuth, signOut } from "firebase/auth";
import { useContext } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import NavItem from "components/NavItem";
import color from "constants/color";
import { useFirebaseContext } from "contexts/FirebaseContext";
import UserContext from "contexts/UserContext";
import { ReactComponent as AimoParkingSvg } from "images/aimoParking.svg";
import { ReactComponent as AtPortSvg } from "images/atPort.svg";
import { ReactComponent as LanditSvg } from "images/landit.svg";
import { ReactComponent as PitPortSvg } from "images/pitPort.svg";
import { ReactComponent as SyncPortSvg } from "images/syncPort.svg";
import AimoParking from "pages/AimoParking";
import AtPort from "pages/AtPort";
import Home from "pages/Home";
import Login from "pages/Login";
import PitPort from "pages/PitPort";
import SyncPort from "pages/SyncPort";
import FirebaseProvider from "providers/FirebaseProvider";
import UserProvider from "providers/UserProvider";

// パーツ定義
const Container = styled.div`
  display: flex;
  min-height: 100vh;
`;
const SideMenu = styled.header`
  display: flex;
  flex-direction: column;
  padding: 0 16px 14px 0;
  width: 240px;
  background-color: ${color.white};
  justify-content: space-between;
  flex: 0;
`;
const Setting = styled.div`
  margin-top: 40px;
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${color.border};
  margin-top: 20px;
  margin-bottom: 10px;
`;
const LanditLogo = styled(LanditSvg)`
  margin-left: 26px;
  width: 157px;
`;
const AtPortLogo = styled(AtPortSvg)`
  margin: 10px 0 14px 22px;
  width: 62px;
`;
const PitPortLogo = styled(PitPortSvg)`
  margin: 10px 0 14px 22px;
  width: 74px;
`;
const SyncPortLogo = styled(SyncPortSvg)`
  margin: 10px 0 14px 22px;
  width: 74px;
`;

const AimoParkingLogo = styled(AimoParkingSvg)`
  margin: 10px 0 14px 22px;
  width: 114px;
`;

// 本体
const Layout = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const navHome = () => navigate("/");
  const navAtPortUser = () => navigate("/at_port/user");
  const navPitPortUser = () => navigate("/pit_port/user");
  const navPitPortProperty = () => navigate("/pit_port/property");
  const navPitPortUsage = () => navigate("/pit_port/usage");
  const navCompany = () => navigate("/pit_port/company");
  const navPitPortControlBox = () => navigate("/pit_port/control_box");
  const navSyncPortCompany = () => navigate("/sync_port/company");
  const navSyncPortUser = () => navigate("/sync_port/user");
  const navAimoParkingCompany = () => navigate("/aimo_parking/company");
  const navAimoParkingUser = () => navigate("/aimo_parking/user");
  const navAimoParkingSystemNotification = () => navigate("/aimo_parking/system_notification");
  const navAimoParkingEdgeDevice = () => navigate("/aimo_parking/edge_device");
  const navAimoParkingAiSoftware = () => navigate("/aimo_parking/ai_software");

  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    navigate("login");
  };

  return (
    <Container>
      <SideMenu>
        <div>
          <LanditLogo />
          <NavItem onClick={navHome} label="ホーム" icon={faHomeAlt} isCurrent={pathname === "/"} />
          <Separator />
          <AtPortLogo />
          <NavItem
            onClick={navAtPortUser}
            label="ユーザ"
            icon={faUserFriends}
            isCurrent={pathname.startsWith("/at_port/user")}
          />
          <Separator />
          <PitPortLogo />
          <NavItem
            onClick={navPitPortUser}
            label="ユーザ"
            icon={faUserFriends}
            isCurrent={pathname.startsWith("/pit_port/user")}
          />
          <NavItem
            onClick={navCompany}
            label="請求先"
            icon={faBuilding}
            isCurrent={pathname.startsWith("/pit_port/company")}
          />
          <NavItem
            onClick={navPitPortProperty}
            label="駐車場"
            icon={faParking}
            isCurrent={pathname.startsWith("/pit_port/property")}
          />
          <NavItem
            onClick={navPitPortControlBox}
            label="制御BOX"
            icon={faHardDrive}
            isCurrent={pathname.startsWith("/pit_port/control_box")}
          />
          <NavItem
            onClick={navPitPortUsage}
            label="利用状況"
            icon={faCar}
            isCurrent={pathname.startsWith("/pit_port/usage")}
          />
          <Separator />
          <SyncPortLogo />
          <NavItem
            onClick={navSyncPortCompany}
            label="管理会社"
            icon={faBuilding}
            isCurrent={pathname.startsWith("/sync_port/company")}
          />
          <NavItem
            onClick={navSyncPortUser}
            label="ユーザ"
            icon={faUserFriends}
            isCurrent={pathname.startsWith("/sync_port/user")}
          />
          <Separator />
          <AimoParkingLogo />
          <NavItem
            onClick={navAimoParkingCompany}
            label="管理会社"
            icon={faBuilding}
            isCurrent={
              pathname.startsWith("/aimo_parking/company") &&
              !pathname.startsWith("/aimo_parking/company/user") &&
              !pathname.includes("company_user")
            }
          />
          <NavItem
            onClick={navAimoParkingUser}
            label="ユーザ"
            icon={faUserFriends}
            isCurrent={pathname === "/aimo_parking/user" || pathname.includes("company_user")}
          />
          <NavItem
            onClick={navAimoParkingSystemNotification}
            label="お知らせ"
            icon={faEnvelope}
            isCurrent={pathname.startsWith("/aimo_parking/system_notification")}
          />
          <NavItem
            onClick={navAimoParkingEdgeDevice}
            label="エッジデバイス"
            icon={faTachographDigital}
            isCurrent={pathname.startsWith("/aimo_parking/edge_device")}
          />
          <NavItem
            onClick={navAimoParkingAiSoftware}
            label="AIバージョン"
            icon={faMicrochip}
            isCurrent={pathname.startsWith("/aimo_parking/ai_software")}
          />
        </div>
        <Setting>
          <NavItem onClick={logout} label="ログアウト" icon={faSignOutAlt} />
        </Setting>
      </SideMenu>
      {children}
    </Container>
  );
};

// userLoadingは読み込み時必ずtrue
// localStorageからFirebase Authenticationのログイン情報を読み込んだらfalseになる
const PrivateRoute = () => {
  const { isUserLoaded } = useContext(UserContext);
  const { auth } = useFirebaseContext();

  // ユーザ情報の読込が終わっていない
  // isUserLoadedは読み込み時必ずfalse
  // localStorageからFirebase Authenticationのログイン情報を読み込んだらtrueになる
  if (!isUserLoaded) return <></>;
  // 非ログイン状態
  if (!auth.currentUser) return <Navigate to="login" replace />;
  // ログイン状態
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

// Routeを設定することで、Switch以下で切り替えられる
const AppRouting = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="" element={<Home />} />
          <Route path="at_port">
            <Route path="user" element={<AtPort.User />} />
            <Route path="user/:id" element={<AtPort.EditUser />} />
          </Route>
          <Route path="pit_port">
            <Route path="user">
              <Route path="" element={<PitPort.User />} />
              <Route path="create" element={<PitPort.CreateUser />} />
            </Route>
            <Route path="user/:id">
              <Route path="" element={<PitPort.EditUser />} />
            </Route>
            <Route path="property">
              <Route path="" element={<PitPort.Property />} />
              <Route path="create" element={<PitPort.CreateProperty />} />
            </Route>
            <Route path="property/:id">
              <Route path="" element={<PitPort.EditProperty />} />
            </Route>
            <Route path="usage" element={<PitPort.Usage />} />
            <Route path="company" element={<PitPort.Company />} />
            <Route path="company/:id">
              <Route path="" element={<PitPort.EditCompany />} />
            </Route>
            <Route path="control_box">
              <Route path="" element={<PitPort.ControlBox />} />
              <Route path="create" element={<PitPort.CreateControlBox />} />
            </Route>
            <Route path="control_box/:id">
              <Route path="" element={<PitPort.EditControlBox />} />
            </Route>
          </Route>
          <Route path="sync_port">
            <Route path="company">
              <Route path="" element={<SyncPort.Company />} />
              <Route path="create" element={<SyncPort.CreateCompany />} />
            </Route>
            <Route path="company/:id">
              <Route path="" element={<Navigate to="info" />} />
              <Route path="edit" element={<SyncPort.EditCompany />} />
              <Route element={<SyncPort.TitleTabArea />}>
                <Route path="info" element={<SyncPort.CompanyDetail />} />
                <Route path="user" element={<SyncPort.CompanyUserInfo />} />
                <Route path="property">
                  <Route path="" element={<SyncPort.Property />} />
                  <Route path="create" element={<SyncPort.CreateProperty />} />
                </Route>
                <Route path="property/:propertyId">
                  <Route path="" element={<Navigate to="space" replace={true} />} />
                  <Route path="image" element={<SyncPort.UploadPropertyImage />} />
                  <Route element={<SyncPort.PropertyDetail />}>
                    <Route path="space" element={<SyncPort.Space />} />
                    <Route path="space_group" element={<SyncPort.SpaceGroup />} />
                  </Route>
                  <Route path=":spaceGroupId" element={<SyncPort.SpaceGroupDetail />} />
                  <Route path=":spaceGroupId/image" element={<SyncPort.UploadParkingGuide />} />
                </Route>
              </Route>
            </Route>
            <Route path="user">
              <Route path="" element={<SyncPort.User />} />
              <Route path="create" element={<SyncPort.CreateUser />} />
            </Route>
            <Route path="user/:id">
              <Route path="edit" element={<SyncPort.EditUser />} />
            </Route>
          </Route>
          <Route path="aimo_parking">
            <Route path="company">
              <Route path="" element={<AimoParking.Company />} />
              <Route path="create" element={<AimoParking.CreateCompany />} />
              <Route path=":id">
                <Route path="" element={<Navigate to="info" replace />} />
                <Route path="edit" element={<AimoParking.EditCompany />} />
                <Route element={<AimoParking.TitleTabArea />}>
                  <Route path="info" element={<AimoParking.CompanyDetail />} />
                  <Route path="user" element={<AimoParking.CompanyUser />} />
                </Route>
                <Route path="company_user">
                  <Route path="create" element={<AimoParking.CreateUser />} />
                  <Route path=":userId/edit" element={<AimoParking.EditUser />} />
                </Route>
              </Route>
            </Route>
            <Route path="user" element={<AimoParking.User />} />
            <Route path="system_notification">
              <Route path="" element={<AimoParking.SystemNotification />} />
              <Route path="create" element={<AimoParking.CreateSystemNotification />} />
              <Route path=":id" element={<AimoParking.SystemNotificationDetail />} />
            </Route>
            <Route path="edge_device">
              <Route path="" element={<AimoParking.EdgeDevice />} />
              <Route path="create" element={<AimoParking.CreateEdgeDevice />} />
              <Route path=":id">
                <Route path="" element={<AimoParking.EdgeDeviceDetail />} />
                <Route path="edit" element={<AimoParking.EditEdgeDevice />} />
              </Route>
            </Route>
            <Route path="ai_software">
              <Route path="" element={<AimoParking.AiSoftware />} />
              <Route path="update" element={<AimoParking.UpdateVersion />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

// Context.Providerで囲んで子要素がContextを使えるようにする
const App = () => {
  return (
    <FirebaseProvider>
      <UserProvider>
        <AppRouting />
      </UserProvider>
    </FirebaseProvider>
  );
};

export default App;
